import { Button } from "components";
import Grid from "elements/Grid";
import InvestorsImage from "public/pricing/investors.svg";
import { useState } from "react";
import { InvestorContactModal } from "../Landing/InvestorLandingModal";
import ButtonV2 from "elements/ButtonV2";

export const PricingInvestorsSection = () => {
	const [showCommercialContactModal, setShowCommercialContactModal] =
		useState(false);

	const handleCommercialContactModalClick = () => {
		setShowCommercialContactModal(true);
	};

	return (
		<div id="pricing-investors-section">
			<Grid>
				<div id="pricing-investors-section-content">
					<div id="pricing-investors-section-image">
						<InvestorsImage />
					</div>
					<div id="pricing-investors-section-cta-container">
						<h3 className="mb-1 mt-3 h3-update">
							Are you a Real Estate Investor?
						</h3>
						<p>
							Whether you're managing a few properties or a large portfolio,
							we&nbsp;can&nbsp;help.
						</p>
						<ButtonV2
							variant="primary-outline-filled"
							size="small"
							onClick={handleCommercialContactModalClick}>
							Sign Up Your Properties
						</ButtonV2>
					</div>
				</div>
			</Grid>
			{showCommercialContactModal && (
				<InvestorContactModal
					setShowCommercialContactModal={setShowCommercialContactModal}
				/>
			)}
		</div>
	);
};
