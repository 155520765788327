import Link from "next/link";
import { Button } from "components";
import Grid from "elements/Grid";
import PricingCard from "../shared/PricingCard";
import { FaqCardCols } from "elements/FaqCard";
import constants from "utils/constants";
import RealtorReferralPartnerBanner from "../Landing/RealtorReferralPartnerBanner";
import { PricingHeading } from "./PricingHeading";
import { PricingCalculator } from "./PricingCalculator";
import { PricingInvestorsSection } from "./PricingInvestorsSection";

import Coinage from "public/landing-faq-icons/coinage.svg";
import Calculator from "public/landing-faq-icons/calculator.svg";
import Piggy from "public/landing-faq-icons/piggy.svg";
import Lock from "public/landing-faq-icons/lock.svg";
import Bill from "public/landing-faq-icons/bill.svg";
import Tax from "public/landing-faq-icons/tax.svg";
import Refer from "public/landing-faq-icons/refer.svg";
import Waat from "public/landing-faq-icons/waat.svg";
import ButtonV2 from "elements/ButtonV2";

export const allPricingFaqQuestions = [
	{
		Icon: <Coinage />,
		title: "How much do you charge?",
		body: "We charge nothing up front and only charge a contingency fee after we save you money on your property taxes. If you don’t save money, our service is entirely free.",
	},
	{
		Icon: <Piggy />,
		title: "How much can I save?",
		body: "Single family homeowners that earn a reduction typically save about $1,148 per year through our service on average. Try out our savings calculator to see how much you could save. Real estate investors can contact sales for an in-depth analysis.",
	},
	{
		Icon: <Tax />,
		title: "How do you lower my taxes?",
		body: "We manage the end-to-end process of property tax reduction, from paperwork filing to discussions with county assessors and attending appeal board hearings. We use a combination of proprietary technology and local knowledge to build the best evidence to reduce property taxes.",
	},
	{
		Icon: null,
		title: "When do I pay? Do I have to pay anything up-front?",
		body: "There are no up-front costs for using our service. You only pay after we’ve earned a tax reduction with a successful appeal.",
	},
	{
		Icon: <Refer />,
		title: "How does the referral program work?",
		body: `You can invite friends and neighbors to sign up for Ownwell by sharing your personalized referral code with them! You can find your referral code in your portal at the bottom right of any property overview page or in your account settings. After your friend signs up and is verified as the property owner, you'll see a ${constants.REFERRAL_CREDIT} reward as a referral credit on your account. Your friend who signed up will also earn a $${constants.REFERRAL_CREDIT} referral credit.`,
	},
	{
		Icon: <Calculator />,
		title: "How is your fee calculated?",
		body: (
			<p>
				Our fee is applied to actual property tax savings in the year we appeal.
				For instance, if our fee is 25% and your property tax bill is reduced
				from $5,000 to $4,000 our fee will be applied to the $1,000 difference.
				In this case, the final invoice amount would be $250. You can find
				pricing for your region <Link href="/pricing">here</Link>.
			</p>
		),
	},
	{
		Icon: <Lock />,
		title: "How are tax exemptions taken into account?",
		body: "Our contingency fee is applied to actual property tax savings in the year we appeal. If you have an active homestead exemption, over 65 exemption, or any other tax exemption applied to your property, we will adjust your final fee by the appropriate discounted amount. You won't be charged unless your final property tax bill is actually reduced, and we have a signed document from your taxing authority to prove it.",
	},
	{
		Icon: <Bill />,
		title: "How are property taxes calculated?",
		body: 'Each year, the county tax assessor generates an estimated market value and an "assessed value" for each property. These values form the base value (“basis”) upon which the property is taxed. The basis is multiplied by the tax rate for your specific tax jurisdictions to calculate the property tax bill.',
	},
	{
		Icon: null,
		title: "How do you get paid?",
		body: "Once the appeal is complete and we’re notified that an appeal was successful, we notify the property owner of the outcome and calculate the amount saved. Any savings achieved are sent directly to the property owner, so we’ll typically send an electronic invoice for payment.",
	},
	{
		Icon: <Waat />,
		title: "Is there any risk to my property by signing up?",
		body: "There is no risk to signing up a property to protest or appeal in Texas, California, New York and Florida. In Washington and Georgia it is possible that a property's taxable value can increase, however we review all relevant market data before filing. If we think there is any risk then we will inform you that we are not filing. It's still important to review your property taxes each year to ensure you're only ever paying what's fair.",
	},
];

export const pricingFaqQuestionsWithIcons = allPricingFaqQuestions.filter(
	({ Icon }) => !!Icon
);

export const pricingFaqQuestions = allPricingFaqQuestions.map(
	({ Icon, ...rest }) => ({ ...rest })
);

const PricingPage = () => {
	return (
		<>
			<RealtorReferralPartnerBanner />
			<div id="pricing-page">
				<PricingHeading />
				<PricingCalculator showBillBreakdown />
				<PricingInvestorsSection />
				<div id="pricing-faq-section">
					<Grid id="pricing-faq-grid">
						<h3 className="h3-update pricing-faq-section-title">FAQ</h3>
						<FaqCardCols faqs={pricingFaqQuestions} />
						<h3 id="pricing-faq-have-questions">Still have questions?</h3>
						<Link passHref href="/help" id="pricing-faq-contact-us">
							<ButtonV2 variant="primary-outline">Contact Us</ButtonV2>
						</Link>
					</Grid>
				</div>
			</div>
		</>
	);
};

export default PricingPage;
