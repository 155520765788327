import Button from "elements/Button";
import Grid from "elements/Grid";
import PricingMap from "public/pricing/pricing.svg";
import Image from "next/image";

import SavingsOrFreeGuarantee from "public/pricing/savings-or-free-guarantee.gif";
import NoHiddenFees from "public/pricing/no-hidden-fees.gif";
import NoUpfrontCosts from "public/pricing/no-upfront-costs.gif";
import ButtonV2 from "elements/ButtonV2";

const cardItems = [
	{
		title: "Savings-or-Free Guarantee",
		gif: SavingsOrFreeGuarantee,
	},
	{
		title: "No Hidden Fees",
		gif: NoHiddenFees,
	},
	{
		title: "No Upfront Costs",
		gif: NoUpfrontCosts,
	},
];

export const PricingHeading = () => {
	return (
		<div id="pricing-hero-section">
			<Grid>
				<PricingMap id="pricing-map-img" height="100%" width="100%" />
				<div id="pricing-title-container">
					<h1 id="pricing-title" className="display1">
						Expert service, affordable rates
					</h1>
					<p id="pricing-body">
						Our property tax experts apply their local knowledge with the best
						data and tools available. Earn more savings — and pay less in fees.
					</p>
					<a href="#pricing-calculator" id="pricing-cta">
						<ButtonV2 size="large">View Pricing</ButtonV2>
					</a>
					<div id="pricing-value-prop-card-container">
						{cardItems.map((cardItem, index) => (
							<div className="pricing-value-prop-card" key={index}>
								<Image src={cardItem.gif} alt={cardItem.title} />
								<p className="sm">{cardItem.title}</p>
							</div>
						))}
					</div>
				</div>
			</Grid>
		</div>
	);
};
